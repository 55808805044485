import React from 'react';
import { PositiveTrendType } from '@features/Analytics/types';
import { ArrowDownRight, ArrowUpRight } from 'react-feather';
import { formatDuration } from '@utils/dates';
import { KpiProps } from '../types';
import { Container, DiffValue, TrendLine, Value } from './styled';
import { NoData } from './NoData';

const roundTo2Decimals = (num: number) => Math.round(num * 100) / 100;

const roundValue = (num: number) => {
  if (num >= 1000000000) {
    return roundTo2Decimals(num / 1000000000) + 'B';
  }
  if (num >= 1000000) {
    return roundTo2Decimals(num / 1000000) + 'M';
  }
  if (num >= 1000) {
    return roundTo2Decimals(num / 1000) + 'K';
  }

  return roundTo2Decimals(num);
};

const formatValue = (num: number, format: KpiProps['numberFormat']) => {
  switch (format) {
    case 'time':
      return formatDuration(num);
    case 'currency':
      return `$${roundValue(num)}`;
    default:
      return roundValue(num);
  }
};

export const Kpi = ({
  isEmpty,
  value,
  diffInPercent,
  positiveTrendType,
  daysInPeriod,
  onClick,
  numberFormat
}: KpiProps) => {
  const isNegative = diffInPercent < 0;
  const isSame = diffInPercent === 0;

  const noTrend = diffInPercent === null;

  if (isEmpty) {
    return <NoData />;
  }

  return (
    <Container onClick={onClick}>
      <Value>{formatValue(value, numberFormat)}</Value>
      {!noTrend && !isSame && (
        <TrendLine>
          {positiveTrendType === PositiveTrendType.UP &&
            (isNegative ? (
              <ArrowDownRight size="24px" color="#D54855" />
            ) : (
              <ArrowUpRight size="24px" color="#009A47" />
            ))}
          {positiveTrendType === PositiveTrendType.DOWN &&
            (!isNegative ? (
              <ArrowUpRight size="24px" color="#D54855" />
            ) : (
              <ArrowDownRight size="24px" color="#009A47" />
            ))}
          <DiffValue>{Math.abs(roundTo2Decimals(diffInPercent))}%</DiffValue>
          <div>
            vs previous {daysInPeriod} day{daysInPeriod > 1 ? 's' : ''}
          </div>
        </TrendLine>
      )}
      {!noTrend && isSame && (
        <TrendLine>
          No changes compared to previous {daysInPeriod} day{daysInPeriod > 1 ? 's' : ''}
        </TrendLine>
      )}
      {noTrend && <TrendLine>No data for comparison</TrendLine>}
    </Container>
  );
};
