import React, { useCallback, useMemo } from 'react';
import { BarChart as RechartsBarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis, Bar } from 'recharts';
import { formatCurrency, formatCurrencyWithoutCents, formatNumber } from '@utils/utils';
import { formatDuration } from '@utils/dates';
import { BarLikeProps, Point } from '../types';
import { NoData } from './NoData';

export const BarChart = ({ numberFormat, points, onClick }: BarLikeProps) => {
  const handleBarClick = useCallback(
    (data: any) => {
      const point = data.payload as Point;

      onClick(point);
    },
    [onClick]
  );

  const leftMargin = useMemo(() => {
    const max = Math.max(...points.map((point) => point.y));

    const resultValue =
      numberFormat === 'number' || numberFormat === 'time' ? formatNumber(max) : formatCurrencyWithoutCents(max);

    return resultValue.length * 5;
  }, [points, numberFormat]);

  const tooltipValueFormatter = useCallback(
    (value: number) => {
      if (numberFormat === 'time') {
        return formatDuration(value);
      }

      return numberFormat === 'number' ? formatNumber(value) : formatCurrency(value);
    },
    [numberFormat]
  );

  const yAxisValueFormatter = useCallback(
    (value: number) => {
      if (numberFormat === 'time') {
        return formatDuration(value);
      }

      return numberFormat === 'number' ? formatNumber(value) : formatCurrencyWithoutCents(value);
    },
    [numberFormat]
  );

  if (!points.length) {
    return <NoData />;
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <RechartsBarChart
        width={500}
        height={300}
        data={points}
        margin={{
          top: 16,
          right: 16,
          left: leftMargin,
          bottom: 32
        }}
      >
        <defs>
          <linearGradient id="barColor" x1="0" y1="1" x2="0" y2="0">
            <stop offset="0%" stopColor="#009688" />
            <stop offset="100%" stopColor="#36EBCA" />
          </linearGradient>
        </defs>
        <CartesianGrid strokeDasharray="4 4" />
        <XAxis tickLine={false} dataKey="x" stroke="#828D9A" />
        <YAxis tickFormatter={yAxisValueFormatter} tickLine={false} axisLine={false} stroke="#828D9A" />
        <Tooltip formatter={tooltipValueFormatter} />
        <Bar name="Value" maxBarSize={24} dataKey="y" fill="url(#barColor)" onClick={handleBarClick} />
      </RechartsBarChart>
    </ResponsiveContainer>
  );
};
