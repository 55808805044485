import { isNumeric } from '@utils/properties';
import { useCompanyProperties } from '@hooks/useCompanyProperties';
import { useMemo } from 'react';
import {
  AnalyticsMeasureEntityType,
  AnalyticsModule,
  MeasureOption,
  PredefinedMeasureId,
  StandardPropertyId
} from '../types';
import {
  ANALYTICS_MODULE_RECORD_TYPE_MAP,
  TIME_TO_COMPLETE_PROPERTY_ID,
  VISITS_PER_WORK_ORDER_PROPERTY_ID
} from '../constants';

const ANALYTICS_MODULE_MEASURE_ENTITY_TYPE_MAP: Record<AnalyticsModule, AnalyticsMeasureEntityType> = {
  [AnalyticsModule.CLIENTS]: AnalyticsMeasureEntityType.CLIENT,
  [AnalyticsModule.REQUESTS]: AnalyticsMeasureEntityType.REQUEST,
  [AnalyticsModule.PROJECTS]: AnalyticsMeasureEntityType.PROJECT,
  [AnalyticsModule.WORK_ORDERS]: AnalyticsMeasureEntityType.WORK_ORDER
};

export const useMeasureOptions = (module: AnalyticsModule): MeasureOption[] => {
  const { properties } = useCompanyProperties();

  return useMemo(() => {
    const numericPropertiesOptions =
      module === AnalyticsModule.WORK_ORDERS
        ? []
        : properties
            .filter(
              (property) =>
                typeof property.id === 'number' &&
                property.id !== 0 &&
                isNumeric(property) &&
                property.scope.includes(ANALYTICS_MODULE_RECORD_TYPE_MAP[module])
            )
            .map((property) => ({
              value: `custom_property_${property.id}`,
              label: property.name,
              property: { id: property.id as number, entityType: ANALYTICS_MODULE_MEASURE_ENTITY_TYPE_MAP[module] }
            }));

    switch (module) {
      case AnalyticsModule.WORK_ORDERS:
        return [
          {
            value: PredefinedMeasureId.WORK_ORDERS,
            label: 'Work Orders',
            property: { id: StandardPropertyId.ID, entityType: AnalyticsMeasureEntityType.WORK_ORDER }
          },
          {
            value: PredefinedMeasureId.VISITS,
            label: 'Visits',
            property: { id: StandardPropertyId.ID, entityType: AnalyticsMeasureEntityType.VISIT }
          },
          {
            value: PredefinedMeasureId.VISITS_PER_WORK_ORDER,
            label: 'Visits per completed WO',
            property: { id: VISITS_PER_WORK_ORDER_PROPERTY_ID, entityType: AnalyticsMeasureEntityType.WORK_ORDER }
          },
          {
            value: PredefinedMeasureId.TIME_TO_COMPLETE,
            label: 'Time to completion',
            property: { id: TIME_TO_COMPLETE_PROPERTY_ID, entityType: AnalyticsMeasureEntityType.WORK_ORDER }
          }
        ];
      case AnalyticsModule.CLIENTS:
        return [
          {
            value: PredefinedMeasureId.CLIENTS,
            label: 'Clients',
            property: { id: StandardPropertyId.ID, entityType: AnalyticsMeasureEntityType.CLIENT }
          },
          ...numericPropertiesOptions
        ];
      case AnalyticsModule.REQUESTS:
        return [
          {
            value: PredefinedMeasureId.REQUESTS,
            label: 'Requests',
            property: { id: StandardPropertyId.ID, entityType: AnalyticsMeasureEntityType.REQUEST }
          },
          {
            value: PredefinedMeasureId.TIME_TO_COMPLETE,
            label: 'Time to completion',
            property: { id: TIME_TO_COMPLETE_PROPERTY_ID, entityType: AnalyticsMeasureEntityType.REQUEST }
          },
          ...numericPropertiesOptions
        ];
      case AnalyticsModule.PROJECTS:
        return [
          {
            value: PredefinedMeasureId.PROJECTS,
            label: 'Projects',
            property: { id: StandardPropertyId.ID, entityType: AnalyticsMeasureEntityType.PROJECT }
          },
          {
            value: PredefinedMeasureId.TIME_TO_COMPLETE,
            label: 'Time to completion',
            property: { id: TIME_TO_COMPLETE_PROPERTY_ID, entityType: AnalyticsMeasureEntityType.PROJECT }
          },
          ...numericPropertiesOptions
        ];
      default:
        return [];
    }
  }, [properties, module]);
};

export const getMeasureOptionGroupBy = (option: MeasureOption) => {
  if (
    option.property.id === StandardPropertyId.ID ||
    option.property.id === VISITS_PER_WORK_ORDER_PROPERTY_ID ||
    option.property.id === TIME_TO_COMPLETE_PROPERTY_ID
  ) {
    return undefined;
  }

  if (option.property.id > 0) {
    return 'Numeric properties';
  }

  return 'Standard properties';
};
