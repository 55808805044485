import {
  AnalyticsMeasureEntityType,
  AnalyticsSubmeasureType,
  AnalyticsWidgetAggregateFunction,
  AnalyticsWidgetDateRangeType,
  AnalyticsWidgetType,
  DimensionType,
  ShowFilterType,
  TimeGranularity,
  WidgetFilter,
  WidgetSettings,
  WorkOrderDimensionType
} from '@features/Analytics/types';
import {
  TaskReportAggregates,
  TaskReportCondition,
  TaskReportFilter,
  TaskReportGroupBy,
  TaskStatus
} from '@generated/types/graphql';
import { DeepPartial } from 'redux';
import { dateRangeConfig } from '@features/Analytics/dateRangeConfig';
import { TIME_TO_COMPLETE_PROPERTY_ID, VISITS_PER_WORK_ORDER_PROPERTY_ID } from '@features/Analytics/constants';
import { WidgetSettingsError } from '@features/Analytics/WidgetSettingsError';
import { DateTime } from 'luxon';
import { InternalConfigutationError } from '@features/Analytics/InternalConfigurationError';
import {
  TIME_GRANULARITY_TO_COMPLETED_AT_GROUP_BY,
  TIME_GRANULARITY_TO_CREATED_AT_GROUP_BY,
  TIME_GRANULARITY_TO_TIMELINE_STATUS_ENDED_AT_GROUP_BY,
  TIME_GRANULARITY_TO_TIMELINE_STATUS_STARTED_AT_GROUP_BY,
  TIME_GRANULARITY_TO_VISIT_STARTED_AT_GROUP_BY
} from './constants';
import { applyFiltersGroup, buildCreateAtDateRangeFilter } from '../helpers';
import { RequestParams } from '../types';
import { BuildRequestArgs } from './types';
import { standardAttributesFilterHandlers } from './filtering';

type WorkOrderRequestParams = RequestParams<TaskReportFilter, TaskReportCondition, TaskReportGroupBy>;

const buildAggregationResponseValueField = (settings: WidgetSettings) => {
  if (settings.measure.id.toString() === VISITS_PER_WORK_ORDER_PROPERTY_ID) {
    return 'visitCount';
  }

  if (settings.measure.id.toString() === TIME_TO_COMPLETE_PROPERTY_ID) {
    return 'completionTimeInS';
  }

  if (settings.measure.entityType === AnalyticsMeasureEntityType.VISIT) {
    return 'visitId';
  }

  return 'id';
};

export const buildAggregationResponseFields = (settings: WidgetSettings) => {
  const valueField = buildAggregationResponseValueField(settings);

  switch (settings.aggregateFunctionId) {
    case AnalyticsWidgetAggregateFunction.COUNT:
      return `
                distinctCount {
                    ${valueField}
                }
            `;
    case AnalyticsWidgetAggregateFunction.SUM:
      return `
                sum {
                    ${valueField}
                }
            `;
    case AnalyticsWidgetAggregateFunction.AVG:
      return `
                average {
                    ${valueField}
                }
            `;
    case AnalyticsWidgetAggregateFunction.MIN:
      return `
                min {
                    ${valueField}
                }
            `;
    case AnalyticsWidgetAggregateFunction.MAX:
      return `
                max {
                    ${valueField}
                }
            `;
    default:
      throw new WidgetSettingsError('Unsupported aggregate function');
  }
};

export const extractGroupAggregationValue = (settings: WidgetSettings, aggregate: TaskReportAggregates) => {
  const valueField = buildAggregationResponseValueField(settings);

  switch (settings.aggregateFunctionId) {
    case AnalyticsWidgetAggregateFunction.COUNT:
      return aggregate.distinctCount[valueField as keyof typeof aggregate.distinctCount];
    case AnalyticsWidgetAggregateFunction.SUM:
      return aggregate.sum[valueField as keyof typeof aggregate.sum];
    case AnalyticsWidgetAggregateFunction.AVG:
      return aggregate.average[valueField as keyof typeof aggregate.average];
    case AnalyticsWidgetAggregateFunction.MIN:
      return aggregate.min[valueField as keyof typeof aggregate.min];
    case AnalyticsWidgetAggregateFunction.MAX:
      return aggregate.max[valueField as keyof typeof aggregate.max];
    default:
      throw new WidgetSettingsError('Unsupported aggregate function');
  }
};

export const buildCommonFilters = (companyId: number, settings: WidgetSettings): DeepPartial<TaskReportFilter> => {
  const result: DeepPartial<TaskReportFilter> = {
    companyId: {
      equalTo: companyId
    }
  };

  if (settings.show === ShowFilterType.ACTIVE) {
    result.isArchived = {
      equalTo: false
    };
  } else if (settings.show === ShowFilterType.ARCHIVED) {
    result.isArchived = {
      equalTo: true
    };
  }

  if (settings.measure.id.toString() === VISITS_PER_WORK_ORDER_PROPERTY_ID) {
    result.isCompleted = {
      equalTo: true
    };
  }

  if (settings.measure.id.toString() === TIME_TO_COMPLETE_PROPERTY_ID) {
    result.status = {
      equalTo: TaskStatus.Completed
    };
  }

  if (settings.measure.entityType === AnalyticsMeasureEntityType.VISIT) {
    result.taskVisitsByTaskIdExist = true;
  }

  if (settings.filters && settings.filters.children.length) {
    const applySingleFilter = (filter: WidgetFilter): DeepPartial<TaskReportFilter> => {
      const handler = standardAttributesFilterHandlers[filter.operator];

      if (!handler) {
        throw new InternalConfigutationError(`Unsupported filter operator ${filter.operator} for Work Orders`);
      }

      return handler({ filter });
    };

    const groupResult = applyFiltersGroup(settings.filters, settings.filters.operator, applySingleFilter);

    result.and = groupResult.and;
    result.or = groupResult.or;
  }

  return result;
};

const buildSubmeasureDateRangeCondition = (
  settings: WidgetSettings,
  calculatePreviousPeriod = false
): DeepPartial<TaskReportCondition> => {
  const dateRangeOption = dateRangeConfig[settings.dateRangeType];

  if (settings.dateRangeType === AnalyticsWidgetDateRangeType.ALL_TIME) {
    return {};
  }

  if (!settings.submeasureId) {
    return {};
  }

  const isEntered = settings.submeasureTypeId === AnalyticsSubmeasureType.ENTERED;

  let greaterThanOrEqualTo;
  let lessThanOrEqualTo;

  greaterThanOrEqualTo = dateRangeOption.startDate ? dateRangeOption.startDate().toISO() : settings.dateRangeStart;
  lessThanOrEqualTo = dateRangeOption.endDate ? dateRangeOption.endDate().toISO() : settings.dateRangeEnd;

  if (!greaterThanOrEqualTo && !lessThanOrEqualTo) {
    return {};
  }

  if (calculatePreviousPeriod) {
    const startDateDateTime = greaterThanOrEqualTo ? DateTime.fromISO(greaterThanOrEqualTo) : undefined;
    const endDateDateTime = lessThanOrEqualTo ? DateTime.fromISO(lessThanOrEqualTo) : undefined;

    const diff = endDateDateTime.diff(startDateDateTime, 'days').days;

    greaterThanOrEqualTo = DateTime.fromISO(greaterThanOrEqualTo).minus({ days: diff }).toISO();
    lessThanOrEqualTo = DateTime.fromISO(lessThanOrEqualTo).minus({ days: diff }).toISO();
  }

  if (isEntered) {
    return {
      timelineStartedStartDate: greaterThanOrEqualTo,
      timelineStartedEndDate: lessThanOrEqualTo
    };
  } else {
    return {
      timelineEndedStartDate: greaterThanOrEqualTo,
      timelineEndedEndDate: lessThanOrEqualTo
    };
  }
};

const buildGroupByLabelCondition = (settings: WidgetSettings): DeepPartial<TaskReportCondition> => {
  if (settings.dimensionId !== DimensionType.LABEL) {
    return {};
  }

  return {
    withLabels: true
  };
};

export const buildVisitCondition = (settings: WidgetSettings): DeepPartial<TaskReportCondition> => {
  if (settings.measure.entityType !== AnalyticsMeasureEntityType.VISIT) {
    return {};
  }

  return {
    withVisits: true
  };
};

const buildCommonCondition = (settings: WidgetSettings): DeepPartial<TaskReportCondition> => {
  const condition: DeepPartial<TaskReportCondition> = {
    ...buildGroupByLabelCondition(settings),
    ...buildVisitCondition(settings)
  };

  return condition;
};

export const buildSubmeasureCondition = (
  settings: WidgetSettings,
  calculatePreviousPeriod = false
): DeepPartial<TaskReportCondition> => {
  if (!settings.submeasureId) {
    return {};
  }

  const statusId = settings.submeasureId.toString().replace('status_', '');

  return {
    withStatusTimeline: true,
    timelineStatus: statusId as TaskStatus,
    ...buildSubmeasureDateRangeCondition(settings, calculatePreviousPeriod)
  };
};

export const buildTimelineFilter = (settings: WidgetSettings): DeepPartial<TaskReportFilter> => {
  if (settings.widgetType !== AnalyticsWidgetType.TIMELINE) {
    return {};
  }

  return {
    timelineStatus: {
      isNull: false
    }
  };
};

export const buildTimelineDateRangeCondition = (settings: WidgetSettings): DeepPartial<TaskReportCondition> => {
  if (settings.widgetType !== AnalyticsWidgetType.TIMELINE) {
    return {};
  }

  const dateRangeOption = dateRangeConfig[settings.dateRangeType];

  if (!dateRangeOption) {
    throw new InternalConfigutationError(`Date range type ${settings.dateRangeType} is not exist`);
  }

  const result = {} as DeepPartial<TaskReportCondition>;

  const startDate = dateRangeOption.startDate ? dateRangeOption.startDate().toISO() : settings.dateRangeStart;
  const endDate = dateRangeOption.endDate ? dateRangeOption.endDate().toISO() : settings.dateRangeEnd;

  if (dateRangeOption.id === AnalyticsWidgetDateRangeType.ALL_TIME) {
    return {
      withStatusTimeline: true,
      timelineStatusIsEnded: true
    };
  }

  if (!startDate && !endDate) {
    return {};
  }

  result.timelineStartedStartDate = startDate;
  result.timelineStartedEndDate = endDate;
  result.timelineEndedStartDate = startDate;
  result.timelineEndedEndDate = endDate;
  result.withStatusTimeline = true;

  return result;
};

export const buildTimelineCondition = (settings: WidgetSettings): DeepPartial<TaskReportCondition> => {
  if (
    ![AnalyticsWidgetType.TIMELINE, AnalyticsWidgetType.PIPELINE, AnalyticsWidgetType.FUNNEL].includes(
      settings.widgetType
    )
  ) {
    return {};
  }

  const result: DeepPartial<TaskReportCondition> = {};

  result.withStatusTimeline = true;

  if (settings.widgetType !== AnalyticsWidgetType.TIMELINE) {
    return result;
  }

  return {
    ...result,
    ...buildTimelineDateRangeCondition(settings)
  };
};

const buildTimeGroupBy = (settings: WidgetSettings): TaskReportGroupBy => {
  if (!settings.submeasureId) {
    if (settings.measure.id.toString() === VISITS_PER_WORK_ORDER_PROPERTY_ID) {
      return TIME_GRANULARITY_TO_COMPLETED_AT_GROUP_BY[settings.subdimensionId as TimeGranularity];
    }

    if (settings.measure.entityType === AnalyticsMeasureEntityType.VISIT) {
      return TIME_GRANULARITY_TO_VISIT_STARTED_AT_GROUP_BY[settings.subdimensionId as TimeGranularity];
    }

    return TIME_GRANULARITY_TO_CREATED_AT_GROUP_BY[settings.subdimensionId as TimeGranularity];
  }

  const isEntered = settings.submeasureTypeId === AnalyticsSubmeasureType.ENTERED;

  return isEntered
    ? TIME_GRANULARITY_TO_TIMELINE_STATUS_STARTED_AT_GROUP_BY[settings.subdimensionId as TimeGranularity]
    : TIME_GRANULARITY_TO_TIMELINE_STATUS_ENDED_AT_GROUP_BY[settings.subdimensionId as TimeGranularity];
};

const groupByMap: Record<
  WorkOrderDimensionType,
  TaskReportGroupBy | ((settings: WidgetSettings) => TaskReportGroupBy)
> = {
  [DimensionType.TIME]: buildTimeGroupBy,
  [DimensionType.TEMPLATE]: TaskReportGroupBy.TemplateTaskId,
  [DimensionType.PRIORITY]: TaskReportGroupBy.Priority,
  [DimensionType.ASSIGNEE]: TaskReportGroupBy.AssigneeId,
  [DimensionType.LABEL]: TaskReportGroupBy.LabelName,
  [DimensionType.STATUS]: TaskReportGroupBy.Status
};

const buildGroupBy = (settings: WidgetSettings): TaskReportGroupBy => {
  const groupBy = groupByMap[settings.dimensionId as WorkOrderDimensionType];

  if (typeof groupBy === 'function') {
    return groupBy(settings);
  }

  return groupBy;
};

const buildWithSubmeasureRequestParams = ({
  companyId,
  settings,
  calculatePreviousPeriod
}: BuildRequestArgs): WorkOrderRequestParams => {
  const filter = {
    ...buildCommonFilters(companyId, settings)
  };

  const condition = {
    ...buildSubmeasureCondition(settings, calculatePreviousPeriod),
    ...buildCommonCondition(settings)
  };

  return {
    filter,
    condition,
    groupBy: buildGroupBy(settings)
  };
};

const buildWithoutSubmeasureRequestParams = ({
  companyId,
  settings,
  calculatePreviousPeriod
}: BuildRequestArgs): WorkOrderRequestParams => {
  const filter = {
    ...buildCommonFilters(companyId, settings),
    ...buildCreateAtDateRangeFilter(settings, calculatePreviousPeriod)
  };

  const condition = {
    ...buildCommonCondition(settings)
  };

  return {
    filter,
    condition,
    groupBy: buildGroupBy(settings)
  };
};

export const buildRequestParams = ({ companyId, settings, calculatePreviousPeriod }: BuildRequestArgs) => {
  if (settings.submeasureId) {
    return buildWithSubmeasureRequestParams({ companyId, settings, calculatePreviousPeriod });
  }

  return buildWithoutSubmeasureRequestParams({ companyId, settings, calculatePreviousPeriod });
};
