import { useMemo } from 'react';
import { ProjectColumnVisibility, ProjectReport } from '@generated/types/graphql';
import { Property, PropertyType, RecordType } from '@types';
import { formatDate, formatDuration } from '@utils/dates';
import {
  AnalyticsSubmeasureType,
  DimensionType,
  PredefinedWidgetFilterFieldId,
  StandardPropertyId,
  WidgetSettings
} from '../types';
import { TIME_TO_COMPLETE_PROPERTY_ID } from '../constants';

const MAP_DIMENSION_TO_PROPERTY: { [type in DimensionType]?: StandardPropertyId } = {
  [DimensionType.CREATOR]: StandardPropertyId.CREATED_BY,
  [DimensionType.OWNER]: StandardPropertyId.OWNER,
  [DimensionType.PROJECT_MANAGER]: StandardPropertyId.PROJECT_MANAGER,
  [DimensionType.SALES_REP]: StandardPropertyId.SALES_REP
};

const WORKFLOW_PROPERTY: Property = {
  id: StandardPropertyId.WORKFLOW,
  name: 'Workflow',
  scope: [RecordType.PROJECT, RecordType.DEAL],
  type: PropertyType.Text,
  objectName: 'blueprint',
  displayValuePath: 'blueprint.name',
  readonly: true,
  isAdditional: false,
  mappedName: 'blueprintId',
  virtual: false,
  virtualType: null,
  position: 0,
  access: [],
  isNewRecord: true,
  externalVisibility: ProjectColumnVisibility.Hidden,
  internalVisibility: ProjectColumnVisibility.Required,
  shared: false,
  sharingType: null,
  iconUrl: null
};

type ComputedProperty = {
  isComputed: true;
  name: string;
  valueField:
    | 'timelineStageStartedAt'
    | 'timelineStageEndedAt'
    | 'timelineStatusStartedAt'
    | 'timelineStatusEndedAt'
    | 'completionTimeInS'
    | 'completionDate';
  formatValue: (report: ProjectReport) => string;
};

type Result = (Property | ComputedProperty)[];

const FIXED_COLUMN_PROPERTIES = [StandardPropertyId.CREATED_AT, StandardPropertyId.SITE_ADDRESS];

export const useRecordsDynamicColumns = (settings: WidgetSettings, propertiesMap: Record<number, Property>): Result => {
  return useMemo(() => {
    const result: Result = [];

    const usedIds = new Set<number>();

    if (settings.measure.id.toString() === TIME_TO_COMPLETE_PROPERTY_ID) {
      result.push(
        ...[
          {
            isComputed: true,
            name: 'Completed at',
            valueField: 'completionDate',
            formatValue: (report: ProjectReport) => {
              const value = report.project?.completionDate;

              return value ? formatDate(value, 'MM/DD/YYYY hh:mm A') : '-';
            }
          },
          {
            isComputed: true,
            name: 'Time to complete',
            valueField: 'completionTimeInS',
            formatValue: (report: ProjectReport) => {
              const value = report.completionTimeInS;

              return value ? formatDuration(value as number) : '-';
            }
          }
        ]
      );
    }

    if (
      settings.measure &&
      settings.measure.id !== StandardPropertyId.ID &&
      settings.measure.id !== TIME_TO_COMPLETE_PROPERTY_ID
    ) {
      result.push(propertiesMap[settings.measure.id]);
      usedIds.add(settings.measure.id);
    }

    if (settings.submeasureId) {
      const isStatus = settings.submeasureId.toString().startsWith('status_');
      const isEntered = settings.submeasureTypeId === AnalyticsSubmeasureType.ENTERED;

      if (isStatus) {
        result.push({
          isComputed: true,
          name: isEntered ? 'Entered date' : 'Exited date',
          valueField: isEntered ? 'timelineStatusStartedAt' : 'timelineStatusEndedAt',
          formatValue: (report: ProjectReport) => {
            const value = isEntered ? report.timelineStatusStartedAt : report.timelineStatusEndedAt;

            return value ? formatDate(value, 'MM/DD/YYYY hh:mm A') : '-';
          }
        });
      } else {
        result.push({
          isComputed: true,
          name: isEntered ? 'Entered date' : 'Exited date',
          valueField: isEntered ? 'timelineStageStartedAt' : 'timelineStageEndedAt',
          formatValue: (report: ProjectReport) => {
            const value = isEntered ? report.timelineStageStartedAt : report.timelineStageEndedAt;

            return value ? formatDate(value, 'MM/DD/YYYY hh:mm A') : '-';
          }
        });
      }
    }

    const dimensionProperty = MAP_DIMENSION_TO_PROPERTY[settings.dimensionId];

    if (dimensionProperty) {
      result.push(propertiesMap[dimensionProperty]);
      usedIds.add(dimensionProperty);
    }

    if (settings.dimensionId === DimensionType.DROPDOWN_PROPERTY && settings.subdimensionId) {
      const propertyId = parseInt(settings.subdimensionId.split('_')[1], 10); // property_{id}
      if (propertiesMap[propertyId]) {
        result.push(propertiesMap[propertyId]);
        usedIds.add(propertyId);
      }
    }

    if (settings.filters) {
      settings.filters.children.forEach((filter) => {
        if ('children' in filter) {
          filter.children.forEach((child) => {
            if ('children' in child) {
              return;
            }

            if (
              child.fieldId === PredefinedWidgetFilterFieldId.WORK_ORDER_TYPE ||
              child.fieldId === PredefinedWidgetFilterFieldId.WORK_ORDER_TEMPLATE ||
              child.fieldId === PredefinedWidgetFilterFieldId.WORK_ORDER_WITH_TITLE
            ) {
              return;
            }

            if (child.fieldId === PredefinedWidgetFilterFieldId.WORKFLOW) {
              if (usedIds.has(StandardPropertyId.WORKFLOW)) {
                return;
              }
              result.push(WORKFLOW_PROPERTY);
              usedIds.add(StandardPropertyId.WORKFLOW);
            } else {
              if (FIXED_COLUMN_PROPERTIES.includes(child.fieldId)) {
                return;
              }

              if (usedIds.has(child.fieldId)) {
                return;
              }

              result.push(propertiesMap[child.fieldId]);
              usedIds.add(child.fieldId);
            }
          });
        } else {
          if (
            filter.fieldId === PredefinedWidgetFilterFieldId.WORK_ORDER_TYPE ||
            filter.fieldId === PredefinedWidgetFilterFieldId.WORK_ORDER_TEMPLATE ||
            filter.fieldId === PredefinedWidgetFilterFieldId.WORK_ORDER_WITH_TITLE
          ) {
            return;
          }

          if (filter.fieldId === PredefinedWidgetFilterFieldId.WORKFLOW) {
            if (usedIds.has(StandardPropertyId.WORKFLOW)) {
              return;
            }

            result.push(WORKFLOW_PROPERTY);
            usedIds.add(StandardPropertyId.WORKFLOW);
          } else {
            if (FIXED_COLUMN_PROPERTIES.includes(filter.fieldId)) {
              return;
            }

            if (usedIds.has(filter.fieldId)) {
              return;
            }
            result.push(propertiesMap[filter.fieldId]);
            usedIds.add(filter.fieldId);
          }
        }
      });
    }

    return result;
  }, [settings, propertiesMap]);
};
